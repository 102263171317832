.treeRoot {
    height: 100%;
}

.listItem {
    list-style-type: none;
}

.draggingSource {
    opacity: 0.3;
}

.placeholder {
    position: relative;
    list-style-type: none;
}

.row {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
}

.icon {
    font-size: 12px !important;
}

.title {
    display: inline-block;
    padding: 5px;
}
