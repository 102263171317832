.background {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  margin: auto;
  z-index: -1;
  object-fit: cover;
  height: 100%;
  background-color: #fff;
}
